import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData, postStbFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function, raptorFormSchema } from "../common/utils"
import { PropertyBaseAPI } from "../../queries/api_links"
import dateFormat from 'dateformat';
import moment from 'moment';

import axios from "axios"
import * as qs from "query-string"

function BookAView(props) {
  const [startDate, setStartDate] = useState();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: props.formName,
        form_type: props.formType,
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "book_a_viewing_user",
        email_temp_admin: "book_a_viewing_admin",
        email_server_func: "book_a_viewing",
        event_tracking: "book_a_viewing",
        page_url: props.location.pathname,
        email_subject_user: "Viewing Request",
        email_subject_admin: "Viewing Request"
      },
      
      {
        grpmd: "12",
        label: "First Name:",
        placeholder: "First Name*",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "content_b-18"
      },

      {
        grpmd: "12",
        label: "Last Name:",
        placeholder: "Last Name*",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Email:",
        placeholder: "Your Email Address*",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18"

      },
      {
        grpmd: "12",
        label: "Telephone:",
        placeholder: "+974 12345678",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18"

      },   
      {
        grpmd: "12",
        label: "Preferred Date & Time:",
        placeholder: "Preferred Date & Time*",
        name: "date_time",
        type: "date_time",
        element: "input",
        // min: today,
        // max: maxday.toString(),
        required: true,
        patternchk: "",
        class: "form-control date_time"
      },
      {
        grpmd: "12",
        label: "Message:",
        placeholder: "Write your message here",
        name: "message",
        element: "textarea",
        class: "mb-4",
        rows:"2"
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Send Enquiry",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper col-md-12 col-sm-12 col-xs-12"
      },
      {
        text: 'By clicking Submit, I agree to The Pearl Gates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
      {
        name: "negotiatoremail",
        type: "hidden",
        element: "input",
        defaultValue: props.subject?.negotiator?.Email
      }
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //event.target.value = event.target.value.trimStart()
    if(event.target.name === "telephone"){
      var valid_phone = phoneNumberValidation(event.target.value);
      if(valid_phone){
        event.target.value = valid_phone;
      } else{
        event.target.value = '';
      }
    } else{
      event.target.value = event.target.value.trimStart()
    }
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

          let formData = new FormData();

          formData.append('data', JSON.stringify(formvalues));

          postFormData(formData).then(async apiRes => {

            formvalues['g_recaptcha_response'] = token;
            await Mail_Function(formvalues);
            window.grecaptcha.reset();

            var property_image = props.formLabel.images && props.formLabel.images.length > 0 ? props.formLabel.images[0].srcUrl : '';
            var property_location = props.formLabel.display_address ? props.formLabel.display_address : '';

            var propDateSplit = moment(formvalues.date_time).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');

            //console.log("propDateSplit", propDateSplit);

            var record_type_id = "0124v0000018HKKAA2";
            var property_id = props.formLabel.propertyID;

            var listing_type = "Sale";

            if(props.formLabel.department === "residential"){
              if(props.formLabel.search_type === "sales"){
                record_type_id = "0124v0000018HKKAA2";
                listing_type = "Sale";
              } else{
                record_type_id = "0124v0000018HKPAA2";
                listing_type = "Rent";
              }
            } else{
              if(props.formLabel.search_type === "sales"){
                record_type_id = "0124v0000018HKFAA2";
                listing_type = "Sale";
              } else{
                record_type_id = "0124v0000018HKAAA2";
                listing_type = "Rent";
              }
            }

            var query_params2 = "contact[RecordTypeId]="+record_type_id+"&contact[Listing_Type__c]="+listing_type+"&contact[FirstName]="+formvalues.first_name+"&contact[LastName]="+formvalues.last_name+"&contact[Email_2__c]="+formvalues.email+"&contact[MobilePhone]="+formvalues.telephone+"&contact[LeadSource]=website&contact[Property_Location__c]="+property_location+"&contact[Property_Images__c]="+property_image+"&contact[New_Website__c]=true&contact[Subscribed_For_Monthly_Newsletter__c]=true&contact[pba__Comment_pb__c]="+property_id+", "+formvalues.message+"&contact[Date_Time_for_Viewing__c]="+propDateSplit+"&contact[pba__LeadRoutingCompleted__c]=false";

            if(props.formLabel.sublisting_type){
              query_params2 += "&contact[Lead_Type__c]="+props.formLabel.sublisting_type;
            }

            // Website views
            query_params2 += "&contact[For_Website_Views__c]=true&contact[Listing_Website__c]="+props.location.pathname+"&contact[Property_ID__c]="+property_id+"&contact[Website_Views__c]=";


            await PropertyBaseAPI(query_params2).then(response => {
              //console.log("property_base res", response)
              if(response.status === 200){
                console.log('property_base', 'success')
              } else{
                console.log('property_base', 'status error')
              }        
            }).catch(err =>
              console.log("property_base", "error")
            );

            // lets send mail
            // const axiosOptions_email = {
            //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
            //   method: "post",
            //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //   data: qs.stringify(formvalues),
            // }

            // axios(axiosOptions_email)
            // .then(response => {
            //   console.log('mail sent!')
            // })
            // .catch(err =>
            //   console.log(err)
            // );

          });
          postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
            console.log('raptor submission', apiRes)
           });

          // const axiosOptions_netlify = {
          //   url: fields[0].page_url,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_netlify)
          //   .then(response => {
          //     console.log('data stored')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );

          // const url = typeof window !== 'undefined' ? window.location.href : ''  
          // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          //   fields[0].formname = 'Get mortgage help';
          // }
          
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+fields[0].event_tracking,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });

          setShowerror(false);
          setThankyou(true);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.formLabel.id ? props.formLabel.id : '';
      json['property_url'] = props.location.pathname ? props.location.pathname : '';
      json['property_title'] = props.formLabel.display_address ? props.formLabel.display_address : '';
      json['property_img'] = props.formLabel.images && props.formLabel.images.length > 0 ? props.formLabel.images[0].srcUrl : '';

      json['g_recaptcha_response'] = token;
      json['extra'] = {"property_id": json.property_id, "property_url": json.property_url, "property_title": json.property_title, "property_img": json.property_img, "preferred_date_time": json.date_time};
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
    }
  };

  //console.log("property_data", props.formLabel, props.location.pathname)

  var routing_source = '';
  if(props.formLabel.propertyID){
    if(props.formLabel.propertyID.includes('TPG-R')){
      routing_source = "Leasing";
    } else if(props.formLabel.propertyID.includes('TPG-CR')){
      routing_source = "Commercial - Leasing";
    } else if(props.formLabel.propertyID.includes('TPG-S')){
      routing_source = "Sales Group A";
    } else if(props.formLabel.propertyID.includes('TPG-CS')){
      routing_source = "Commercial - Sales";
    } 
  }

  return (
    <div className="form stbform">
      <div ref={myRef} />
    {showerror && <div className="alert alert-error">
      {fields[0].error_text}
    </div>}

    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form className="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="project_name" value={props.formLabel.display_address} />
        <input type="hidden" name="lead_source" value={'Website'} />
        <input type="hidden" name="routing_source" value={routing_source} />
        <input type="hidden" name="routing_completed" value={'False'} />
        <input type="hidden" name="crm_id" value={props.formLabel.crm_id} />

        <input type="hidden" name="bot-field" />

        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  startDate={field.type === 'date_time' ? startDate : ''}
                  setStartDate={field.type === 'date_time' ? setStartDate : ''}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  phoneNumber={field.name === 'telephone' ? phoneNumber : ''}
                  setPhoneNumber={field.name === 'telephone' ? setPhoneNumber : ''}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }

        </Form.Row>
      </Form>

    </div>
  );
}


const BookAViewForm = (props) => (
  <BookAView {...props}  />
)

export default BookAViewForm
